import React from 'react'
import Layout from '../components/layout'

import { ProjectHeaderComponent, ProjectLinksComponent, TwitterTimeline } from '../components/project'
import SEO from '../components/seo'
import { NewsList } from '../components/news'
import { MediumAtricles } from '../components/medium'

const ResourceTemplate = ({ data }) => {

  const twitterProfileName = data.strapiResource.twitter_link?.match(/^https?:\/\/(www\.)?twitter\.com\/(#!\/)?([^\/]+)(\/\w+)*$/)[3];

  const resourceId = data.strapiResource.articles[0]?.resource;

  const news = data.allStrapiArticle.edges
    .filter(x => x.node.resource?.id == resourceId)
    .map(x => {
      return {
        id: x.node.id,
        content: x.node.content,
        author: x.node.author,
        title: x.node.title,
        creationTime: x.node.creationTime,
        image: x.node.image,
      }
    })

  return (
    <Layout>
      <SEO title="Resource" />
      <div className="mgt-medium column page-content">
        <div className="column">
          <ProjectHeaderComponent data={data.strapiResource} />
          <ProjectLinksComponent data={data.strapiResource} />
        </div>
        {!!data.strapiResource.articles.length &&
          <NewsList isLarge={true} titleClass="is-3" contentClass="is-size-4" title="News" news={news} />}
        {data.strapiResource.medium_handle &&
          <MediumAtricles title="Medium Articles" mediumPage={data.strapiResource.medium_handle} />}
        {data.strapiResource.twitter_link &&
          <TwitterTimeline title="Twitter Timeline" profile={twitterProfileName} />}
      </div>
    </Layout>
  )
}
export default ResourceTemplate
export const query = graphql`  
  query ResourceTemplate($id: String) {
    strapiResource(id: {eq: $id}) {
      name
      long_description
      grant
      github_link
      website_link
      telegram_link
      twitter_link
      medium_link
      medium_handle
      reddit_link
      youtube_link
      discord_link
      riot_link
      subsocial_link
      whitepaper_link
      articles{
        resource
      }
      email
      logo {
        childImageSharp {
          fixed(width: 90, height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }      
    }
    allStrapiArticle {
      edges {
        node {
          id
          content
          author
          title
          creationTime
          resource{
            id
          }
          image {
            childImageSharp {
              fluid(quality:100) {
                ...GatsbyImageSharpFluid
              }
            }
          }       
        }
      }
    }
  }
`